<template>
  <div class="ma-9">
    <v-data-table
        :headers="headers"
        :items="listPart"
    >
      <template v-slot:item.audio="{ item }">
        <audio class="audio-block" ref="audio" v-if="item.audio" controls="controls">
          <source :src="item.audio ? item.audio :'' " type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDetail(item.id)">
          mdi-lead-pencil
        </v-icon>
      </template>
    </v-data-table>

<!--    dialog edit-->

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  GET_LIST_PART,
  DELETE_PART,
  GET_DETAIL_PART
} from "@/store/exams.module";

export default {
  components: {
    HnrButton: () => import("@/components/elements/hnr-button"),
    HnrInput:() => import("@/components/elements/hnr-input"),
    CkContent:() => import("@/components/ck-content"),
  },
  //list news
  data() {
    return {
      headers: [
        {text: "id", value: "id"},
        {text: "Tên bài viết", value: "title"},
        {text: "File", value: "audio"},
        {text: "Actions", value: "actions", sortable: false}
      ],
      dialogEdit: false,
      audio_message: "",
      file: null,
      audio: {},
    };
  },

  async created() {
    await this.$store.dispatch(GET_LIST_PART);
    var payload = {
      part_id: this.$route.query.part_id
    }
    await this.$store.dispatch(GET_DETAIL_PART, payload);
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sách",titledad:'Sách' }]);
    await this.reloadAudio();
  },

  computed: {
    ...mapGetters({
      detailPart: "detailPart",
      listPart: "listPart",
      group_question: "groupQuestionDetail"
    })
  },
  methods: {
    reloadAudio() {
      let vid = this.$refs.audio;
      if (vid) {
        vid.load();
      }
    },
    addPart() {
      this.dialog = true;
    },
    getDetail(id){
      this.$router.push(`/exam/list/part-edit/${id}`)
    },
    async deleteItem(id) {
      console.log(id,'id')
      if (confirm("Bạn muốn xóa part?")) {
        await this.$store.dispatch(DELETE_PART,{
          id: id,

        } ) .then(data =>{
          if (data.data.status){
            this.$toasted.success('Xóa thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration : 1000
            });
            this.$store.dispatch(GET_LIST_PART);
          }
        });
      }
    },
  }
};
</script>